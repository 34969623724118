#bc-embedded-checkout {
  min-height: 400px;
  font-family: Poppins, sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.6;
  color: #272727;

  a {
    color: inherit;
    text-decoration: underline;
  }

  .form-label {
    font-weight: 700;
  }

  .form-input {
    border-radius: 0;
    box-shadow: none;
    border: solid 1px #b5b5b5;
    height: 58px;
  }

  .customerEmail-action {
    padding-left: 10px;
  }

  .button {
    border-radius: 0;
    height: 58px;

    &:hover {
      background: #F7DD4A;
    }
  }

  .form-checkbox + .form-label::before,
  .form-radio + .form-label::before {
    border: solid 1px #b5b5b5;
    border-radius: 0;
  }


  .checkout-step {
    padding: 12px 0;

    .stepHeader {
      padding: 23px 0 20px;
    }

    .stepHeader-counter {
      height: 38px;
      width: 38px;

      &::before {
        font-size: 22px;
        font-weight: 700;
        color: #141414;
        line-height: 38px;
      }

      &--complete {
        transform: scale(1);

        svg {
          display: none;
        }

        &::before {
          content: counter(li-counter);
        }
      }
    }

    .stepHeader-title {
      font-size: 26px;
      font-weight: 700;
      line-height: 1.38;
      letter-spacing: normal;
      color: #000000;
    }
  }

  .cart {
    border-color: #ececec;
    border-radius: 0;
    box-shadow: none;
  }

  .cart-header,
  .cart-section {
    padding-left: 22px;
    padding-right: 22px;
  }

  .cart-header {
    font-size: 14px;
    font-weight: 700;

    h3 {
      font-size: 18px;
    }
  }

  .cart-section {
    border-color: #ececec;
    font-size: 15px;

    .redeemable-label {
      font-weight: 600;
      font-style: italic;
      display: block;
      position: relative;

      &::after {
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        position: absolute;
        right: 10px;
        height: 10px;
        width: 10px;
        transform-origin: 50% 50%;
        transform: rotate(45deg);
      }
    }
  }

  .cart-header-link {
    position: relative;

    &::after {
      background: #f9d60d;
      content: '';
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      transition: all .2s ease;
    }

    &:hover {
      &::after {
        transform: scaleY(1.05);
      }
    }
  }

  .product {
    align-items: center;
  }

  .product-actions {
    align-self: center;
  }

  .productList-item {
    h5 {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.6;
    }
  }

  .cart-priceItem--subtotal {
    font-weight: 400;
  }

  .cart-priceItem--total .cart-priceItem-value {
    font-size: 24px;
  }
}
